<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="`/${home}`">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/role">Role</b-breadcrumb-item>
      <b-breadcrumb-item active>Add Role</b-breadcrumb-item> </b-breadcrumb
    ><br />

    <b-card title="Add Role">
      <b-row style="margin-bottom:15px;">
        <b-col cols="12">
          <b-button variant="primary" to="/role">
            Back to Role
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Name" label-for="form-name">
            <b-form-input id="form-name" v-model="form.name" />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Level" label-for="form-level">
            <b-form-input id="form-level" v-model="form.level" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="permission-border">
        <b-col
          cols="12"
          style="overflow-y: scroll; max-height: 500px; width: 100%; overflow-x: hidden;"
        >
          <feather-icon
            icon="LockIcon"
            style="width:20px; height:20px; margin-right:15px;"
          />
          <span class="font-weight-bold" style="font-size:15px;"
            >Permissions</span
          >

          <div
            v-for="(domain, domain_index) in domain"
            :key="`domain${domain_index}`"
          >
            <hr />

            <strong style="font-size:15px;"> All Menu </strong>
            <feather-icon
              v-if="show[domain_index]"
              size="16"
              icon="ChevronDownIcon"
              @click="collapseFunction(domain_index)"
              style="cursor: pointer;"
            />
            <feather-icon
              v-if="!show[domain_index]"
              size="16"
              icon="ChevronUpIcon"
              @click="expandFunction(domain_index)"
              style="cursor: pointer;"
            />

            <div v-if="show[domain_index]">
              <b-form-checkbox
                v-model="domain.allAccess"
                :value="true"
                :unchecked-value="false"
                style="margin-top:10px;"
              >
                All Access <span style="color: red;">(Except Picking Pop Up Notification)</span>
              </b-form-checkbox>
              <div v-if="!domain.allAccess">
                <!-- Grouping only in the display -->
                <div
                  v-for="(tr, index) in domain.menu"
                  :key="domain.name + index"
                >
                  <!-- Check if the menu is part of "Dashboard" -->
                  <template v-if="['Machine Dashboard'].includes(tr.name)">
                    <div v-if="index == 0" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Dashboard Section</strong>
                    </div>
                    <b-row v-if="index == 0" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Dashboard" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Master Data" -->
                  <template v-if="['Picklist History', 'Baby Drum', 'Hardware', 'Station', 'Reason List'].includes(tr.name)">
                    <div v-if="index == 1" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Master Data</strong>
                    </div>
                    <b-row v-if="index == 1" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Master Data" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Job Order" -->
                  <template v-if="['Ongoing Job Order', 'Completed Job Order'].includes(tr.name)">
                    <div v-if="index == 6" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Job Order</strong>
                    </div>
                    <b-row v-if="index == 6" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Job Order" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Drum Picking" -->
                  <template v-if="['Ongoing Drum Picking', 'Completed Drum Picking'].includes(tr.name)">
                    <div v-if="index == 8" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Drum Picking</strong>
                    </div>
                    <b-row v-if="index == 8" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Drum Picking" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Direct Sales" -->
                  <template v-if="['Direct Sales'].includes(tr.name)">
                    <div v-if="index == 10" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Direct Sales Section</strong>
                    </div>
                    <b-row v-if="index == 10" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Direct Sales" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Machine" -->
                  <template v-if="['Machine'].includes(tr.name)">
                    <div v-if="index == 11" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Machine Section</strong>
                    </div>
                    <b-row v-if="index == 11" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Machine" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Recoil" -->
                  <template v-if="['Recoil'].includes(tr.name)">
                    <div v-if="index == 12" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Recoil Section</strong>
                    </div>
                    <b-row v-if="index == 12" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Recoil" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Packing" -->
                  <template v-if="['Ongoing Packing', 'Completed Packing'].includes(tr.name)">
                    <div v-if="index == 13" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Packing Section</strong>
                    </div>
                    <b-row v-if="index == 13" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Packing" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Delivery Picking" -->
                  <template v-if="['Ongoing Delivery Picking', 'Completed Delivery Picking'].includes(tr.name)">
                    <div v-if="index == 15" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Delivery Picking Section</strong>
                    </div>
                    <b-row v-if="index == 15" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <!-- <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col> -->
                    </b-row>

                    <!-- Render the menus under "Delivery Picking" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <!-- <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col> -->
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Role" -->
                  <template v-if="['Role'].includes(tr.name)">
                    <div v-if="index == 17" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Role Section</strong>
                    </div>
                    <b-row v-if="index == 17" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Role" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "Pause Setting" -->
                  <template v-if="['Pause Setting'].includes(tr.name)">
                    <div v-if="index == 18" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">Pause Setting Section</strong>
                    </div>
                    <b-row v-if="index == 18" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "Pause Setting" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- Check if the menu is part of "User" -->
                  <template v-if="['User'].includes(tr.name)">
                    <div v-if="index == 19" style="font-size:15px; background-color: #525050 ; color:rgb(255, 255, 255)">
                      <hr />
                      <strong style="margin-left: 10px;">User Section</strong>
                    </div>
                    <b-row v-if="index == 19" style="margin-top:10px;">
                      <b-col cols="2">
                        <strong>Menu</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>View</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Add</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Edit</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Delete</strong>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <strong>Notify</strong>
                      </b-col>
                    </b-row>

                    <!-- Render the menus under "User" -->
                    <b-row style="margin-top:10px;">
                      <b-col cols="2">
                        {{ tr.name }}
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.view"
                          v-if="tr.hasOwnProperty('view')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.add"
                          v-if="tr.hasOwnProperty('add')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.edit"
                          v-if="tr.hasOwnProperty('edit')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.delete"
                          v-if="tr.hasOwnProperty('delete')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                      <b-col cols="2" style="display: table; margin: 0 auto;">
                        <b-form-checkbox
                          v-model="tr.notify"
                          v-if="tr.hasOwnProperty('notify')"
                          :value="true"
                          :unchecked-value="false"
                        ></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-col> </b-row
      ><br />

      <b-col>
        <div>
          <b-button class="float-right" variant="primary" @click="addRole">
            Save
          </b-button>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { viewAccess } from "@/utils/utils.js";

export default {
  data() {
    return {
      form: {
        name: "",
        level: "",
        permission: "",
      },
      show: [true, true, true, true, true, true, true],
      domain: [
        {
          name: "Common",
          allAccess: false,
          menu: [
            {
              name: "Machine Dashboard",
              view: false,
            },
            {
              name: "Picklist History",
              view: false,
            },
            {
              name: "Baby Drum",
              view: false,
            },
            {
              name: "Hardware",
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
            {
              name: "Station",
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
            {
              name: "Reason List",
              view: false,
              add: false,
              edit: false,
            },
            {
              name: "Ongoing Job Order",
              view: false,
              add: false,
              edit: false,
              delete: false,
              notify: false,
            },
            {
              name: "Completed Job Order",
              view: false,
            },
            {
              name: "Ongoing Drum Picking",
              view: false,
              edit: false,
              notify: false,
            },
            {
              name: "Completed Drum Picking",
              view: false,
            },
            {
              name: "Direct Sales",
              view: false,
              edit: false,
            },
            {
              name: "Machine",
              view: false,
              edit: false,
            },
            {
              name: "Recoil",
              view: false,
              edit: false,
            },
            {
              name: "Ongoing Packing",
              view: false,
            },
            {
              name: "Completed Packing",
              view: false,
            },
            {
              name: "Ongoing Delivery Picking",
              view: false,
              edit: false,
              // notify:false,
            },
            {
              name: "Completed Delivery Picking",
              view: false,
              edit: false,
              // notify:false,
            },
            {
              name: "Role",
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
            {
              name: "Pause Setting",
              view: false,
              edit: false,
            },
            {
              name: "User",
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
          ],
        },
      ],
    };
  },
  methods: {
    collapseFunction(index) {
      this.show = this.show.map((el, mapIndex) => {
        if (index === mapIndex) {
          return false;
        } else {
          return el;
        }
      });
    },

    expandFunction(index) {
      this.show = this.show.map((el, mapIndex) => {
        if (index === mapIndex) {
          return true;
        } else {
          return el;
        }
      });
    },

    async addRole() {
      if (this.form.name === "Super Admin") {
        this.$bvToast.toast("Super Admin name is prohibited.", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }

      const hasAtLeastOneTrueMenu = this.domain.some(item =>
        item.menu.some(menuItem =>
          Object.values(menuItem).some(value => value === true)
        )
      );

      if (!hasAtLeastOneTrueMenu && !this.domain.some(item => item.allAccess)) {
        this.$bvToast.toast("At least one menu option must be selected.", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      
      await this.$store
        .dispatch("role/addRole", {
          ...this.form,
          permission: this.domain,
          app: this.$session.get("phibase-app"),
        })
        .then(() => {
          this.$bvToast.toast("Successfully Add Role", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        this.$router.push("/role");
      })
      .catch((err) => {
        console.log({ err });
        this.$bvToast.toast(err.response.data.errors[0].message, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      });
    },
  },
  computed: {
    home() {
      return viewAccess()[0];
    },
  },
  created() {
    document.title = "Add Role | Tai sin - Common";
  },
};
</script>

<style>
.permission-border {
  border-radius: 10px;
  border: 2px solid #cccccc;
  padding: 20px;
  margin: 5px;
}
</style>
